import { render, staticRenderFns } from "./PortalDate.vue?vue&type=template&id=0b7f0d58&scoped=true&"
import script from "./PortalDate.vue?vue&type=script&lang=ts&"
export * from "./PortalDate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b7f0d58",
  null
  
)

export default component.exports