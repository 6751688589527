import FileMetaDTO from "@/dto/files/FileMetaDTO";
import EmploymentDTO from "@/dto/employee/EmploymentDTO";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {IEmployment} from "@/dto/payroll/IEmployment";
import {PayPeriodType} from "@/constants/TarifficationConstants";

export default class CreateDemoPayrollDetailsPayload {
    payPeriodStart: Date | null = null
    payPeriodEnd: Date | null = null
    regularHours: number | null = null;
    overtimeHours: number | null = null;
    ptoHours: number | null = null;
    sickLeaveHours: number | null = null;
    bonus: number | null = null;
    employmentId: number | null = null;
    tips: number | null = null;
    expenseReimburse: number | null = null;
    payDate: Date | null = null
}

export class GetPayPeriodDetailsInfoPayload {
    payPeriodStart: Date | null = null
    payPeriodEnd: Date | null = null
    employmentId: number | null = null;
}

export class PayPeriodDetails {
    start!: string
    end!: string
    regularHours!: number
    overtimeHours!: number
}

export class NewPayrollPeriodDetails {
    start!: Date
    end!: Date
    payDate!: Date;
    regularHours!: number
    overtimeHours!: number
    employment!: EmployeeDto
    ptoHours: number | null = null;
    sickLeaveHours: number | null = null;
    doubleTimeHours: number | null = null;
    holidayHours: number | null = null;
    bonus: number | null = null;
    employmentId: number | null = null;
    owedTips: number | null = null;
    paidTips: number | null = null;
    commission: number | null = null;
    expenseReimburse: number | null = null;
    isSelected = true
}

export class CreatePayrollFormPayload {
    payPeriodStart: Date | null = null
    payPeriodEnd: Date | null = null
    regularHours: number | null = null;
    overtimeHours: number | null = null;
    ptoHours: number | null = null;
    doubleTimeHours: number | null = null;
    holidayHours: number | null = null;
    sickLeaveHours: number | null = null;
    bonus: number | null = null;
    employmentId: number | null = null;
    owedTips: number | null = null;
    paidTips: number | null = null;
    commission: number | null = null;
    expenseReimburse: number | null = null;
    payDate: Date | null = null

    constructor(init?: Partial<CreatePayrollFormPayload>) {
        Object.assign(this, init)
    }
}

export class Paystub {
    id!: number
    employment!: EmployeeDto
    payDate!: string
    payPeriodStart!: string
    payPeriodEnd!: string
    earnings!: Array<EarningDefinition>
    totalTaxableEarning!: EarningDefinition
    nonTaxableIsPresent!: boolean
    expenseReimburse!: EarningDefinition
    totalEarning!: EarningDefinition
    amounts!: Array<Amount>
    totalAmount!: Amount
    totalNetPay!: Amount
    payType!: string
    fileMeta!: FileMetaDTO
}

export class PaystubFilter {
    year: string | null = null
    employmentsId: Array<number> | number | null = null
    payDateStart: Date | null = null
    payDateEnd: Date | null = null
    payPeriodStart: Date | null = null
    payPeriodEnd: Date | null = null
    orderDirection: "ASC" | "DESC" = "DESC"
    page = 0
    size = 20

    constructor(init?: Partial<PaystubFilter>) {
        Object.assign(this, init)
    }
}

export class NewPayrollFilter {
    employerId: number | null = null;
    payPeriodStart: Date | null = null;
    payPeriodEnd: Date | null = null;
    payDate: Date | null = null;

    constructor(init?: Partial<NewPayrollFilter>) {
        Object.assign(this, init)
    }
}

class EarningDefinition{
    title!: string
    hours!: string
    rate!: string
    gross!: string
    grossYtd!: string
}

class Amount {
    title!: string
    gross!: string
    grossYtd!: string
}

export class CreateGeneralPayrollDetails {
    employmentIds: Array<number> = [];
    payPeriodStart: Date | null = null;
    payPeriodEnd: Date | null = null;
}



export class PayrollDetailsDTO {
    id!: number;
    fileMeta!: FileMetaDTO;
    transactionDate!: string;
    payDate!: string;
    payPeriodStart!: string;
    payPeriodEnd!: string;
    source!: string;
    employment!: EmployeeDto;
    hours!: Array<HourItem>;
    totalHours!: string;
    earnings!: Array<Earning>;
    taxableTotal!: string;
    expenseReimburse!: string;
    total!: string;
    employeeTaxes!: Array<TarifficationPair>;
    employeeWages!: Array<TarifficationPair>;
    totalEmployeeTax!: string;
    netPay!: string;
    employerTaxes!: Array<TarifficationPair>;
    employerWages!: Array<TarifficationPair>;
    totalEmployerTax!: string;
    type!: string
}

class HourItem {
    title!: string
    hours!: string
}

class Earning {
    title!: string
    gross!: string
}

class TarifficationPair {
    first!: string;
    second!: number;
}

export class PayrollDetailsFilter {
    employmentId: Array<number> | number | null = null
    year: string | null = null
    type: string | null = null
    payPeriodStart: Date | null = null
    payPeriodEnd: Date | null = null
    page = 0
    offset = 20
}