import axios from 'axios';
import {
    PAYROLL_DEMO_DETAILS_ENDPOINT_V2, PAYROLL_DEMO_PAYSTUB_ENDPOINT_V2,
    PAYROLL_DETAILS_ENDPOINT, PAYROLL_DETAILS_ENDPOINT_V2,
    PAYROLL_ENDPOINT_V1, PAYROLL_ENDPOINT_V2,
    PAYROLL_FORM_1042S_ENDPOINT, PAYROLL_FORM_1099_ENDPOINT,
    PAYROLL_FORM_K1_ENDPOINT,
    PAYROLL_INCOME_TAX_ENDPOINT,
    PAYROLL_INCOME_TAX_LIST_ENDPOINT,
    PAYROLL_INCOME_TAX_YEARS_ENDPOINT,
    PAYROLL_PAYSTUB_ENDPOINT, PAYROLL_PAYSTUB_ENDPOINT_V2,
    PAYROLL_REPORTS_ENDPOINT,
    PAYROLL_REPORTS_PERIODS_ENDPOINT, TIMESHEET_SERVICE
} from '@/constants/endpoints'
import authHeader from "@/services/auth-header";
import PayrollFileDTO from "@/dto/payroll/PayrollFileDTO";
import SavePaystubRequestDTO from "@/dto/payroll/SavePaystubRequestDTO";
import QueryUtils from "@/utils/QueryUtils";
import CollectionUtils from "@/utils/CollectionUtils";
import SavePayrollFileRequest from "@/dto/payroll/SavePayrollFileRequest";
import CreatePayrollDetailsRequestDTO from "@/dto/payroll/CreatePayrollDetailsRequestDTO";
import PayrollReportFilter from "@/dto/payroll/PayrollReportFilter";
import PayrollReportDTO from "@/dto/payroll/PayrollReportDTO";
import IncomeTaxReturnDocumentDTO from "@/dto/payroll/IncomeTaxReturnDocumentDTO";
import Form1042SDTO from "@/dto/payroll/Form1042SDTO";
import FormK1DTO from "@/dto/payroll/FormK1DTO";
import {CounterpartyType} from "@/constants/CounterpartyType";
import Form1099DTO from "@/dto/payroll/Form1099DTO";
import {Upload1099FormPayload} from "@/dto/payroll/Upload1099FormPayload";
import IncomeTaxReturnRequest from "@/dto/payroll/IncomeTaxReturnRequest";
import CreateDemoPayrollDetailsPayload, {
    CreateGeneralPayrollDetails,
    CreatePayrollFormPayload,
    GetPayPeriodDetailsInfoPayload,
    NewPayrollFilter,
    NewPayrollPeriodDetails,
    PayPeriodDetails,
    PayrollDetailsDTO,
    PayrollDetailsFilter,
    Paystub,
    PaystubFilter
} from "@/dto/payroll/PayrollDTO";
import Pageable from "@/dto/Pageable";
import {
    CreateTimeSheetRecordPayload,
    TimeSheetFilter,
    TimeSheetPayPeriod, TimeSheetRecord, UpdateTimeSheetRecordPayload
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

class PayrollService {

    getPayrollFilesByCategoryAndEmployeeId(category: string, employeeId: number) {
        const queryString = QueryUtils.toQueryString(
            CollectionUtils.createStringStringMap(
                'category', category,
                'employeeId', `${employeeId}`));
        return axios.get<Array<PayrollFileDTO>>(`${PAYROLL_ENDPOINT_V1}${queryString}`, {headers: authHeader()});
    }

    getPayrollFilesByCategoryAndEmployeeIdAndEmployerId(category: string, employeeId: number, employerId: number) {
        const queryString = QueryUtils.toQueryString(
            CollectionUtils.createStringStringMap(
                'category', category,
                'employeeId', `${employeeId}`,
                'employerId', `${employerId}`));
        return axios.get<Array<PayrollFileDTO>>(`${PAYROLL_ENDPOINT_V1}${queryString}`, {headers: authHeader()});
    }

    getPayrollFilesByCategoryAndEmployerId(category: string, employerId: number) {
        const queryString = QueryUtils.toQueryString(
            CollectionUtils.createStringStringMap(
                'category', category,
                'employerId', `${employerId}`));
        return axios.get<Array<PayrollFileDTO>>(`${PAYROLL_ENDPOINT_V1}${queryString}`, {headers: authHeader()});
    }

    savePaystub(request: SavePaystubRequestDTO) {
        return axios.put<PayrollFileDTO>(PAYROLL_PAYSTUB_ENDPOINT, request, {headers: authHeader()});
    }

    savePayrollFile(request: SavePayrollFileRequest) {
        return axios.put<PayrollFileDTO>(PAYROLL_ENDPOINT_V1, request, {headers: authHeader()});
    }

    getPayrollFiles(category: string, employeeId: number | null, employerId: number | null, period: string | null) {
        const queryString = QueryUtils.toQueryString(
            CollectionUtils.createPathParamsMap(
                ['category', category],
                ['employeeId', employeeId],
                ['employerId', employerId],
                ['period', period]
            ));
        return axios.get<Array<PayrollFileDTO>>(`${PAYROLL_ENDPOINT_V1}${queryString}`, {headers: authHeader()});
    }

    savePaystubs(fileMetaIds: Array<number>, companyId: number) {
        return axios.post<Array<PayrollFileDTO>>(PAYROLL_PAYSTUB_ENDPOINT, {fileMetaIds: fileMetaIds, companyId: companyId}, {headers: authHeader()});
    }

    stamp(fileMetaIds: number[]) {
        return axios.post<Array<number>>(`${PAYROLL_PAYSTUB_ENDPOINT}/stamp`, {fileMetaIds: fileMetaIds}, {headers: authHeader()});
    }

    savePayrollDetails(request: CreatePayrollDetailsRequestDTO) {
        return axios.post<PayrollFileDTO>(PAYROLL_DETAILS_ENDPOINT, request, {headers: authHeader()});
    }

    getReports(filter: PayrollReportFilter) {
        const queryString = QueryUtils.buildQueryString(
            ["companyId", filter.companyId],
            ["category", filter.category],
            ["period", filter.period]
        );
        return axios.get<Array<PayrollReportDTO>>(`${PAYROLL_REPORTS_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    batchUploadReports(companyId: number, fileMetaIds: Array<number>, category: string) {
        return axios.post<void>(PAYROLL_REPORTS_ENDPOINT, {companyId: companyId, fileMetaIds: fileMetaIds, category: category}, {headers: authHeader()});
    }

    getReportPeriods(companyId: number, category: string) {
        const queryString = QueryUtils.buildQueryString(
            ["companyId", companyId],
            ["category", category]
        );
        return axios.get<Array<string>>(`${PAYROLL_REPORTS_PERIODS_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    saveIncomeTaxList(payload: IncomeTaxReturnRequest) {
        return axios.post<void>(PAYROLL_INCOME_TAX_LIST_ENDPOINT, payload, {headers: authHeader()});
    }

    getYearsForIncomeTax(personId: number, category: string) {
        const queryString = QueryUtils.buildQueryString(
            ["personId", personId],
            ["category", category]
        );
        return axios.get<Array<string>>(PAYROLL_INCOME_TAX_YEARS_ENDPOINT + queryString, {headers: authHeader()});
    }

    getIncomeTaxDocuments(personId: number, year: string, category: string) {
        const queryString = QueryUtils.buildQueryString(
            ["personId", personId],
            ["year", year],
            ["category", category]
        );
        return axios.get<Array<IncomeTaxReturnDocumentDTO>>(PAYROLL_INCOME_TAX_ENDPOINT + queryString, {headers: authHeader()});
    }

    deleteDocument(id: number, type: string) {
        return axios.delete(`${PAYROLL_ENDPOINT_V1}/${type}/${id}`, {headers: authHeader()});
    }

    saveForm1042S(counterpartyId: number, fileMetaId: number, year: string) {
        return axios.post(PAYROLL_FORM_1042S_ENDPOINT,
            {counterpartyId: counterpartyId, fileMetaId: fileMetaId, year: year},
            {headers: authHeader()});
    }

    getForms1042S(counterpartyId: number) {
        const queryString = QueryUtils.buildQueryString(
            ['counterpartyId', counterpartyId]
        );
        return axios.get<Array<Form1042SDTO>>(PAYROLL_FORM_1042S_ENDPOINT + queryString, {headers: authHeader()});
    }

    saveFormK1(shareholderId: number, fileMetaId: number, year: string) {
        return axios.post(PAYROLL_FORM_K1_ENDPOINT,
            {shareholderId: shareholderId, fileMetaId: fileMetaId, year: year},
            {headers: authHeader()});
    }

    getFormsK1(shareholderId: number) {
        const queryString = QueryUtils.buildQueryString(
            ['shareholderId', shareholderId]
        );
        return axios.get<Array<FormK1DTO>>(PAYROLL_FORM_K1_ENDPOINT + queryString, {headers: authHeader()});
    }

    getForms1099(contractorType: CounterpartyType | null, contractorId?: number | null, employerId: number | null = null) {
        const queryString = QueryUtils.buildQueryString(
            ["contractorType", contractorType],
            ["contractorId", contractorId],
            ["employerId", employerId]
        )
        return axios.get<Array<Form1099DTO>>(PAYROLL_FORM_1099_ENDPOINT + queryString, {headers: authHeader()});
    }

    saveForm1099(payload: Upload1099FormPayload){
        return axios.post(PAYROLL_FORM_1099_ENDPOINT, payload, {headers: authHeader()});
    }

    createDemoPayrollDetails(req: Array<CreatePayrollFormPayload>) {
        return axios.post<Array<PayrollDetailsDTO>>(`${PAYROLL_DEMO_DETAILS_ENDPOINT_V2}`, req, {headers: authHeader()})
    }

    createDemoPaystub(req: CreateDemoPayrollDetailsPayload) {
        return axios.post<PayrollDetailsDTO>(`${PAYROLL_DEMO_PAYSTUB_ENDPOINT_V2}`, req, {headers: authHeader()})
    }

    createPayrollDetails(req: Array<CreatePayrollFormPayload>) {
        return axios.post<Array<PayrollDetailsDTO>>(`${PAYROLL_DETAILS_ENDPOINT_V2}`, req, {headers: authHeader()})
    }

    createPaystub(req: CreatePayrollFormPayload) {
        return axios.post<Paystub>(`${PAYROLL_PAYSTUB_ENDPOINT_V2}`, req, {headers: authHeader()})
    }

    createGeneralPayrollDetails(req: CreateGeneralPayrollDetails) {
        return axios.post<PayrollDetailsDTO>(`${PAYROLL_DETAILS_ENDPOINT_V2}/general`, req, {headers: authHeader()})
    }

    getPayrollDetails(filter: PayrollDetailsFilter) {
        return axios.get<Pageable<PayrollDetailsDTO>>(`${PAYROLL_DETAILS_ENDPOINT_V2}${QueryUtils.objectToQueryString(filter)}`, {headers: authHeader()})
    }

    getPaystubs(filter: PaystubFilter) {
        const queryString = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<Paystub>>(`${PAYROLL_PAYSTUB_ENDPOINT_V2}${queryString}`, {headers: authHeader()})
    }

    getTimeSheetPayPeriod(filter: TimeSheetFilter) {
        const queryString = QueryUtils.objectToQueryString(filter)
        return axios.get<Array<TimeSheetPayPeriod>>(`${TIMESHEET_SERVICE}${queryString}`, {headers: authHeader()})
    }

    createTimesheetReport(filter: TimeSheetFilter) {
        const queryString = QueryUtils.objectToQueryString(filter)
        return axios.post<FileMetaDTO>(`${TIMESHEET_SERVICE}/report${queryString}`, {}, {headers: authHeader()})
    }

    createTimesheetRecord(req: CreateTimeSheetRecordPayload) {
        return axios.post<void>(`${TIMESHEET_SERVICE}`, req, {headers: authHeader()})
    }

    updateTimesheetRecord(req: UpdateTimeSheetRecordPayload) {
        return axios.put<void>(`${TIMESHEET_SERVICE}`, req, {headers: authHeader()})
    }

    removeRecord(record: TimeSheetRecord) {
        return axios.delete(`${TIMESHEET_SERVICE}/${record.employment.id}/${record.id}`, {headers: authHeader()})
    }

    getPayrollDetailsPeriodDetails(payload: GetPayPeriodDetailsInfoPayload) {
        const query = QueryUtils.objectToQueryString(payload)
        return axios.get<PayPeriodDetails>(`${PAYROLL_ENDPOINT_V2}/period/payroll_details${query}`, {headers: authHeader()})
    }

    getPaystubPeriodDetails(payload: GetPayPeriodDetailsInfoPayload) {
        const query = QueryUtils.objectToQueryString(payload)
        return axios.get<PayPeriodDetails>(`${PAYROLL_ENDPOINT_V2}/period/paystub${query}`, {headers: authHeader()})
    }

    importPayroll(id: number) {
        return axios.post<void>(`${PAYROLL_ENDPOINT_V2}/import?fileMetaId=${id}`, {}, {headers: authHeader()})
    }

    loadApplicableEmploymentsForFilter(filter: NewPayrollFilter) {
        const query = QueryUtils.objectToQueryString(filter)
        return axios.get<Array<NewPayrollPeriodDetails>>(`${PAYROLL_ENDPOINT_V2}${query}`, {headers: authHeader()})
    }
}

export default new PayrollService();