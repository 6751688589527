



















import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import moment from "moment";

export type mode = "time" | "datetime-local" | "date"

@Component
export default class PortalTime extends Vue {
	private inputMirror: string | null = null

	@VModel({type: Date})
	private inputValue!: Date;

	@Prop({default: "time"})
	private type!: mode;

	@Prop({default: null})
	private name!: string | null;

	@Prop({default: null})
	private id!: string | null;
	private idInternal: string | null = this.id;

	@Prop({default: null})
	private hint!: string | null;

	@Prop()
	private label!: string;

	@Prop({default: null})
	private max!: string | null;

	@Prop({default: null})
	private min!: string | null;

	@Prop({default: null})
	private placeholder!: string | null;

	@Prop({default: ''})
	private validatePattern!: string;

	@Prop({default: undefined})
	private date!: Date

	private emit() {
		switch (this.type) {
			case "time":
				if (this.inputMirror!.split(":").length == 2) {
					this.$emit("input", this.convertTimeToDate())
				}
				break
			case "datetime-local":
			case "date":
				if (this.inputMirror) {
					this.$emit("input", new Date(this.inputMirror))
				}
		}
	}

	private convertTimeToDate() {
		let time: string[] = this.inputMirror!.split(":")
		if (time.length == 2) {
			this.date.setHours(+time[0], +time[1])
		}
		return this.date
	}

	private formatInputMirror(inputValue: Date) {
		inputValue.toISOString()
		switch (this.type) {
			case "time": return moment(inputValue).format("HH:mm")
			case "datetime-local": return moment(inputValue).format("yyyy-MM-DDTHH:mm")
			case "date": return moment(inputValue).format("yyyy-MM-DD")
		}
	}

	public created(): void {
		if (this.inputValue != undefined) {
			this.date = this.inputValue
			this.inputMirror = this.formatInputMirror(this.inputValue)
		}
		this.$validator.extend("date_format", {
			getMessage: field => `The ${field} must be in the format yyyy-MM-ddTHH:mm`,
			validate: () => true
		})
	}

	get isRequired(): boolean {
		return this.validatePattern?.includes("required");
	}
}
