














import {Component, Vue, Watch} from "vue-property-decorator";
import {nodeMap, RouterTreeNode} from "@/router/RouterTree";
import Workspaces from "@/state/Workspaces";
import UserDTO from "@/dto/UserDTO";
import {namespace} from "vuex-class";
import Auth from "@/state/Auth";
import {Route} from "vue-router";

const Auth_ = namespace("Auth");
const AppModule = namespace("App");

@Component({
	computed: {
		nodeMap() {
			return nodeMap
		}
	}
})
export default class Viewer extends Vue {

	private links: Array<RouterTreeNode> = [];

	private nodeName: string | null = "";

	public mounted(): void{
		const newElement = nodeMap.get(this.$route.name || null);
		let node = (newElement?.children.length == 0 ? nodeMap.get(newElement.parentName) : newElement) as RouterTreeNode;
		this.links = node.children.filter(node => this.isAvailable(node) && !node.isHidden)
		this.nodeName = node.name
	}

	@Watch('$route')
	public onRouteChanged(): void {
		const newElement = nodeMap.get(this.$route.name || null);
		let node = (newElement?.children.length == 0 ? nodeMap.get(newElement.parentName) : newElement) as RouterTreeNode;
		this.links = node.children.filter(node => this.isAvailable(node) && !node.isHidden)
		this.nodeName = node.name
	}

	isAvailable(node: RouterTreeNode): boolean {
		if(this.isStateless(node) && node.role && !Auth.hasPermission(node.role)){
			return false;
		}
		if(node.isLoggedIn && !this.isLoggedIn){
			return false;
		}
		if(node.isLoggedOut && this.isLoggedIn){
			return false;
		}
		if(node.isAdmin && !this.hasAdministrativePrivileges){
			return false;
		}
		if(!node.availableForAdminOnSidePanelByDefault
			&& this.hasAdministrativePrivileges
			&& Workspaces.getCurrent?.userId == this.currentUser.id
			&& this.$route.name != node.name
			&& !node.hasChildren(this.$route.name as string)){
			return false;
		}
		if (!node.availableForEveryoneOnSidePanelByDefault
			&& this.$route.name != node.name
			&& !node.hasChildren(this.$route.name as string)) {
			return false;
		}
		if (!this.isStateless(node) && !node.availableForCompany && Workspaces.isCurrentWorkspaceCompany) {
			return false;
		}
		if (!this.isStateless(node) && !node.availableForPerson && Workspaces.isCurrentWorkspacePerson) {
			return false;
		}
		if(!this.isStateless(node) && !node.availableForEmployee && Workspaces.isCurrentWorkspaceSublimeEmployee){
			return false;
		}
		if(node.role && Workspaces.getCurrent?.userId != this.currentUser.id && !Auth.hasPermission(node.role)){
			return false;
		}
		return true;
	}

	isStateless(node: RouterTreeNode): boolean {
		return !(node.availableForCompany || node.availableForPerson || node.availableForEmployee);
	}

	@AppModule.State
	private isMobile!: boolean;

	@Auth_.State("user")
	private currentUser!: UserDTO;

	@Auth_.Getter("hasAdministrativePrivileges")
	private hasAdministrativePrivileges!: boolean;

	@Auth_.Action
	private signOut!: () => void;

	@Auth_.Getter
	private isLoggedIn!: boolean;
}
