class ErrorLocalePathUtils {


    private paths: Map<number, string> = new Map<number, string>();

    private defaultPath = "An error has occurred. Please contact to system administrator."

    constructor() {
        this.paths.set(4001, "error.username_taken");
        this.paths.set(4002, "error.passwords_are_not_equal");
        this.paths.set(4003, "error.password_requirements_error");
        this.paths.set(4004, "error.terms_not_accepted");
        this.paths.set(4005, "error.invalid_confirmation_code");
        this.paths.set(4006, "error.email_is_not_confirmed");
        this.paths.set(4007, "error.ssn_is_not_filled");
        this.paths.set(4008, "error.email_taken");
        this.paths.set(4009, "error.ssn_taken");
        this.paths.set(40010, "error.company_ein_taken");
        this.paths.set(40011, "error.prohibited");
        this.paths.set(40012, "error.questionnaire_already_exists");
        this.paths.set(4011, "error.refresh_token_error");
        this.paths.set(4012, "error.bad_credentials");
        this.paths.set(40013, "error.invalid_invitation_code");
        this.paths.set(40015, "error.invalid_old_password");
        this.paths.set(40016, "error.itin_is_not_filled");
        this.paths.set(40017, "error.invalid_payment_code");
        this.paths.set(40018, "error.employee_not_found");
        this.paths.set(40019, "error.invalid_format_ein");
        this.paths.set(40022, "error.spouse_not_is_self");
        this.paths.set(4014, "error.invalid_spouse_data");
        this.paths.set(40021, "error.invalid_period");
        this.paths.set(40023, "error.ssn_taken_detail");
        this.paths.set(40024, "error.ein_taken_detail");
        this.paths.set(40025, "error.email_taken_detail");
        this.paths.set(40026, "error.email_for_person_exist");
        this.paths.set(4042, "error.email_not_found");
        this.paths.set(40027, 'error.invalid_state')
        this.paths.set(40029, 'error.invalid_routing_number');
        this.paths.set(40030, 'error.account_number_exist');
        this.paths.set(40031, 'error.invalid_account_number');
        this.paths.set(40032, 'error.invalid_holder_type');
        this.paths.set(40033, 'error.tin_taken_detail');
        this.paths.set(40034, 'error.tin_taken');
        this.paths.set(40035, 'error.invalid_public_link');
        this.paths.set(40037, 'error.employment_exist');
        this.paths.set(40038, 'error.not_closed_record');
        this.paths.set(40039, 'error.invalid_time_end_before_start');
        this.paths.set(40040, 'error.invalid_time_end_equal_start');
        this.paths.set(40041, 'error.invalid_time_previous_end_after_current_start');
        this.paths.set(40042, 'error.invalid_time_previous_end_after_current_end');
        this.paths.set(40043, 'error.no_record');
        this.paths.set(40044, 'error.invalid_cross_record_period');
        this.paths.set(40045, 'error.admin_no_person');
        this.paths.set(40046, 'error.invalid_include_record_period');
    }

    public getPathForCode(code: number | undefined) : string {
        return code ? this.paths.get(code) || this.defaultPath : this.defaultPath;
    }


    public getPathForError(error: any) : string {
        return this.getPathForCode(error?.response?.data?.systemCode);
    }

}

export default new ErrorLocalePathUtils();