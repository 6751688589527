export default class SelectOption {
    public title: string | null = null;

    public value: any | null = null;

    public static builder(): Builder {
        return new Builder();
    }
}

class Builder {
    private selectOption!: SelectOption;

    constructor() {
        this.selectOption = new SelectOption();
    }

    public title(title: string | null): Builder {
        this.selectOption.title = title;
        return this;
    }

    public value(value: any, ): Builder {
        this.selectOption.value = value;
        return this;
    }

    public build(): SelectOption {
        return this.selectOption;
    }
}