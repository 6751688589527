



























































































import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import SelectOption from "@/components/common/SelectOption";
import {PropType} from "vue";
import e from "express";
import PortalInput from "@/components/common/PortalInput.vue";

@Component({
	components: {PortalInput}
})
export default class PortalSelect extends Vue {
	private isOpen = false;

	private searchableSubString = ""

	@VModel( {type: [Object, Array, String, Boolean, Number, null] as PropType<any>})
	private select!: any;

	@Prop({default: "select"})
	private id!: string;

	@Prop({default: null})
	private hint!: string | null;

	@Prop({default: false})
	private multiple!: boolean;

	@Prop({default: false})
	private searchable!: boolean

	@Prop({default:function () {return () => {return;};}})
	private change!: () => void

	@Prop({default: "id"})
	private objectKey!: string;

	@Prop()
	private label!: string;

	@Prop({default: ""})
	private validatePattern!: string;

	@Prop({default: function () {return []}})
	private options!: Array<SelectOption>;

	isNotSelected(option: SelectOption): boolean {
		if (this.model) {
			if (this.multiple) {
				if (typeof option.value == 'object' && option.value !== null) {
					return (this.model as Array<SelectOption>).map(inp => inp.value[this.objectKey]).indexOf(option.value[this.objectKey]) == -1
				}
				return (this.model as Array<any>).indexOf(option) == -1
			} else {
				if (typeof option.value == 'object' && option.value) {
					return this.model.value[this.objectKey] != option.value[this.objectKey]
				}
				return this.model != option;
			}
		} else {
			return true
		}
	}

	get model(): any {
		if (this.multiple) {
			let existValues: Array<SelectOption> = [];
			if (this.select) {
				existValues = this.options.filter(option => {
					const res = (this.select as Array<any>).map(sel => {
						if (typeof sel == 'object' && sel) {
							return option.value[this.objectKey] == sel[this.objectKey]
						} else {
							return sel == option.value
						}
					})
					return res.indexOf(true) != -1;
				})
				return existValues;
			} else {
				return null;
			}
		} else {
			if (typeof this.select == 'object' && this.select) {
				const res = this.options.find(val => val.value[this.objectKey] == this.select[this.objectKey])
				return res ? res : null;
			}
			const res = this.options.find(val => val.value == this.select);
			return res ? res : null;
		}
	}

	get isNotValid(): boolean {
		const res = this.$validator.errors.items.find(item => Object(item).vmId == Object(this.$validator).id);
		return !!res;
	}


  get isRequired(): boolean{
    return this.validatePattern.includes("required");
  }
	private toggle() {
	  this.isOpen = !this.isOpen
	}

	private click(option: SelectOption): void {
		if (this.multiple) {
			this.$emit('input', [...(this.model as Array<any>), option].map(val => val.value))
		} else {
			this.$emit('input', option.value)
		}
		this.searchableSubString = ""
		this.toggle();
		this.change();
	}

	private flush(val: SelectOption) {
		if (this.multiple) {
			this.$emit('input', (this.model as Array<any>).filter(i => i != val).map(val => val.value))
		} else {
			this.$emit('input', undefined)
		}
		this.searchableSubString = ""
	}

	get qualifiedOptions(): Array<SelectOption> {
		if (this.searchable && this.searchableSubString != "") {
			return this.options.filter(it => JSON.stringify(it).trim()
				.replaceAll("\"title\":", "")
				.replaceAll("\"value\":", "")
				.toLowerCase()
				.indexOf(this.searchableSubString.toLowerCase()) >= 0
			)
		}
		return this.options
	}
}

