



















import {Vue, Component, Prop, Model} from 'vue-property-decorator';
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileService from "@/services/FileService";
import Workspaces from "@/state/Workspaces";
import {PropType} from "vue";

@Component
export default class SingleFileHolder extends Vue {

  @Model('change', { type: [Object, FileMetaDTO, null] as PropType<FileMetaDTO | null> })
  readonly fileValue!: FileMetaDTO | null;

  @Prop()
  placeholder!: string;

  @Prop()
  onFileUploaded!: (file: FileMetaDTO) => void;

  @Prop()
  onFileDeleted!: () => void;

  @Prop({default: true})
  showFileName!: boolean;

  @Prop({default: true})
  deletable!: boolean;

  @Prop({default: false})
  required!: boolean;

  uploadFile() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.addEventListener('change', async () => {
      if (fileInput.files && fileInput.files[0]) {
        const file = fileInput.files[0];
        const uploadedFile: FileMetaDTO = await this.uploadToServer(file);
        this.$emit('change', uploadedFile);
        this.onFileUploaded(uploadedFile);
      }
    });
    fileInput.click();
  }

  async uploadToServer(file: File) {
    return FileService.uploadFile(file, Workspaces.getCurrent ? Workspaces.getCurrent.userId : null, null).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
    );
  }

  deleteFile() {
    this.$emit('change', null);
    this.onFileDeleted();
  }




}
