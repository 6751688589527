





























import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import {v4} from "uuid";

@Component
export default class BTPortalCollapse extends Vue {
	@Prop({default: v4()})
	private id!: string | null;

	@Prop({default: "Title"})
	private label!: string | null;

	@VModel( {type: Boolean})
	private isOpen!: boolean | undefined

	private isOpenMirror = false;

	public created(): void {
		this.isOpenMirror = !!this.isOpen
	}

	get isOpenM(): boolean {
		return !!this.isOpen || this.isOpenMirror
	}

	private toggle() {
		this.isOpen = !this.isOpen
		this.isOpenMirror = !this.isOpenMirror
		this.$emit("input", this.isOpen)
	}
}

