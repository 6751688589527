export function prettyEnum(enumItem: any): string {
    return enumItem.substring(0, 1) + enumItem.substring(1).toLowerCase().replaceAll("_", " ")
}

export function hashCode(value: string): number {
    let hash = 0;
    for (let i = 0; i < value.length; i++) {
        const char = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0;
    }
    return hash;
}