import axios from 'axios';
import {
    FILE_ARCHIVE_ENDPOINT,
    FILE_ARCHIVE_LIST_ENDPOINT,
    FILE_CUSTOM_ENDPOINT,
    FILE_DOWNLOAD_ENDPOINT, FILE_PUBLISH_LINK_ENDPOINT,
    FILE_SIGNABLE_ENDPOINT,
    FILE_SIGNABLE_LIST_ENDPOINT,
    FILE_SIGNABLE_REFRESH_ENDPOINT,
    FILE_UPLOAD_ENDPOINT,
    FILE_USER_FILES_LIST_ENDPOINT
} from '@/constants/endpoints'
import authHeader from "@/services/auth-header";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import AllUserFilesResponseDTO from "@/dto/files/AllUserFilesResponseDTO";
import CustomUserFileDTO from "@/dto/files/CustomUserFileDTO";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import ArchiveFileDTO from "@/dto/files/ArchiveFileDTO";

class FileService {

    uploadFile(file: any, userId: number | null, customName: string | null) {
        const formData = new FormData();
        formData.append("file", file);
        if (userId) {
            formData.append("userId", userId.toString(10));
        }
        if (customName) {
            formData.append("customName", customName);
        }

        return axios.put<FileMetaDTO>(FILE_UPLOAD_ENDPOINT, formData, {headers: authHeader()})
    }

    downloadFile(fileId: number){
        return axios.get(`${FILE_DOWNLOAD_ENDPOINT}/${fileId}`, {headers: authHeader(), responseType: 'blob'});
    }

    loadAllUserFiles(userId: number) {
        return axios.get<AllUserFilesResponseDTO>(`${FILE_USER_FILES_LIST_ENDPOINT}/${userId}`, {headers: authHeader()})
    }

    saveCustomFile(file: CustomUserFileDTO){
        return axios.put<CustomUserFileDTO>(FILE_CUSTOM_ENDPOINT, file, {headers: authHeader()});
    }

    deleteCustomFile(file: CustomUserFileDTO){
        return axios.post(FILE_CUSTOM_ENDPOINT, file, {headers: authHeader()});
    }

    saveSignableDocument(document: SignableDocumentDTO){
        return axios.put<void>(FILE_SIGNABLE_ENDPOINT, document, {headers: authHeader()});
    }

    loadSignableDocuments(userId: number){
        return axios.get<Array<SignableDocumentDTO>>(`${FILE_SIGNABLE_LIST_ENDPOINT}/${userId}`, {headers: authHeader()});
    }

    saveArchiveFIle(file: ArchiveFileDTO) {
        return axios.put<ArchiveFileDTO>(FILE_ARCHIVE_ENDPOINT, file, {headers: authHeader()});
    }

    loadArchiveFiles(category: string, userId: number){
        return axios.get<Array<ArchiveFileDTO>>(`${FILE_ARCHIVE_LIST_ENDPOINT}?category=${category}&userId=${userId}`, {headers: authHeader()});
    }

    publishDocument(id: number) {
        return axios.put<string>(`${FILE_PUBLISH_LINK_ENDPOINT}/${id}`, null, {headers: authHeader()})
    }
}

export default new FileService();