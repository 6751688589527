













































import {Component, Prop, Vue} from "vue-property-decorator";
import {TimeSheetRecord} from "@/dto/payroll/timesheet/TimesheetPayloads";
import PortalTime from "@/components/common/PortalTime.vue";
import {ifValid} from "@/utils/ComponentUtils";

@Component({
	components: {PortalTime}
})
export default class TimeSheetRecordEditorModal extends Vue {

	@Prop()
	private record!: TimeSheetRecord

	@Prop()
	private onUpdate!: (record: TimeSheetRecord) => void

	public created() {
		if (this.record.originalStartTime) {
			this.record.originalStartTime = new Date(this.record.originalStartTime)
		}
		if (this.record.originalEndTime) {
			this.record.originalEndTime = new Date(this.record.originalEndTime)
		}
	}

	private edit(): void {
		ifValid(this, () => {
			this.$modal.hideAll()
			this.onUpdate(this.record);
		})
	}
}

