import axios from 'axios';
import {
    EMPLOYMENT_BATCH_ENDPOINT, EMPLOYMENT_ENDPOINT
} from '@/constants/endpoints'
import authHeader from "@/services/auth-header";
import QueryUtils from "@/utils/QueryUtils";
import EmploymentDTO from "@/dto/employee/EmploymentDTO";
import CreateEmploymentRequestDTO from "@/dto/employee/CreateEmployeeRequestDTO";
import Workspaces from "@/state/Workspaces";
import {IEmployment} from "@/dto/payroll/IEmployment";
import {EmployeeFilter, EmploymentFilter} from "@/dto/payroll/Filters";
import Pageable from "@/dto/Pageable";
class EmploymentService {

    batchUpload(fileId: number){
        return axios.post(`${EMPLOYMENT_BATCH_ENDPOINT}/${fileId}`,{},{headers: authHeader()});
    }

    createEmployment(employee: CreateEmploymentRequestDTO){
        return axios.post<EmploymentDTO>(EMPLOYMENT_ENDPOINT, employee, {headers: authHeader()});
    }

    getAllByFilter<T extends IEmployment>(filter: EmploymentFilter){
        const queryString = QueryUtils.objectToQueryString(filter)
        return axios.get<Pageable<T>>(`${EMPLOYMENT_ENDPOINT}${queryString}`, {headers: authHeader()});
    }

    getAllEmploymentsByDetailsId(detailsId: number = Workspaces.getCurrent.id) {
        return axios.get<Array<IEmployment>>(`${EMPLOYMENT_ENDPOINT}/${detailsId}`, {headers: authHeader()})
    }

}

export default new EmploymentService();