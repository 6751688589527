




















































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import moment from "moment/moment";
import PortalDate from "@/components/common/PortalDate.vue";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {EmploymentFilter} from "@/dto/payroll/Filters";
import Workspaces from "@/state/Workspaces";
import {processError} from "@/utils/ComponentUtils";
import Application from "@/state/Application";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";
import PortalTime from "@/components/common/PortalTime.vue";
import {
	CreateTimeSheetRecordPayload,
	TimeSheetFilter,
	TimeSheetPayDate,
	TimeSheetPayPeriod,
	TimeSheetRecord,
	UpdateTimeSheetRecordPayload
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import PayrollService from "@/services/PayrollService";
import Notifications from "@/state/Notifications";
import {prettyEnum} from "@/utils/StringUtils";
import EmploymentService from "@/services/EmploymentService";
import {EmploymentStatus} from "@/components/payroll/business/employments/contractors/Contractor";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import {PayType} from "@/constants/TarifficationConstants";
import TimeSheetRecordEditorModal from "@/components/payroll/timesheet/TimeSheetRecordEditorModal.vue";
import RouteNames from "@/router/RouteNames";
import ConfirmComponent from "@/components/payroll/business/ConfirmComponent.vue";
import SelectOption from "@/components/common/SelectOption";
import {DateTime} from "luxon";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {Moment} from "moment-timezone";
import {CounterpartyType} from "@/constants/CounterpartyType";
import {EmploymentType} from "@/constants/EmploymentType";
import TimeSheetReportCreationModal from "@/components/payroll/timesheet/TimeSheetReportCreationModal.vue";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";

@Component({
	computed: {
		PayType() {
			return PayType
		}
	},
	methods: {
		RouteNames() {
			return RouteNames
		},
		prettyEnum,
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		}
	},
	components: {PortalSelect, PaginationComponent, PortalTime, BTPortalCollapse, PortalDate}
})
export default class BusinessTimesheet extends Vue {

	private week = moment().format("YYYY-MM-DD")

	private dataComplete = false

	private timeLine: Array<Moment> = [];

	private totalPages = 0;

	private timeFormat = "hh:mm A z"

	private timesheetFilter = new TimeSheetFilter()

	private employments: Array<EmployeeDto> = [];
	private employmentFilter: EmploymentFilter = new EmploymentFilter(
		{
			types: ["STAFF", "FOREIGN"],
			contractorType: CounterpartyType.PERSON,
			employerId: Workspaces.getCurrent.id,
			status: [EmploymentStatus.ACTIVE, EmploymentStatus.PENDING_FOR_DOCUMENTS, EmploymentStatus.NEW]
		}
	)
	private timesheetWeeks: Array<TimeSheetPayPeriod> = []

	public loadEmployees(): Promise<void> {
		return EmploymentService.getAllByFilter<EmployeeDto>(this.employmentFilter).then(
			res => {
				this.employments = res.data.data
				this.totalPages = res.data.countOfPages
			},
			err => processError(err, this)
		)
	}

	@Watch("week")
	private reload() {
		this.initCurrentTimeLine(moment(this.week));
		this.loadEmployees()
			.then(() => this.loadTimeSheetPayDates())
			.then(() => this.dataComplete = true)
			.then(() => Application.stopLoading()
			)
	}

	public loadTimeSheetPayDates(): Promise<void> {
		this.timesheetFilter.employmentsId = this.employments.map(it => it.id).map(it => it as number)
		this.timesheetFilter.startDate = this.timeLine[0].format("YYYY-MM-DD")
		this.timesheetFilter.endDate = this.timeLine[this.timeLine.length - 1].format("YYYY-MM-DD")
		this.timesheetFilter.timeZone = this.timeZone()

		Application.startLoading()
		return PayrollService.getTimeSheetPayPeriod(this.timesheetFilter).then(
			res => {
				this.timesheetWeeks = res.data
				this.timesheetWeeks.forEach(it => it.payDates = this.prepareTimeSheetRecords(it.payDates, it.employment))
			},
			err => processError(err, this)
		)
	}

	private prepareTimeSheetRecords(payDates: Array<TimeSheetPayDate>, employment: IEmployment): Array<TimeSheetPayDate> {
		const res: Array<TimeSheetPayDate> = [];
		for (let i = 0; i < this.timeLine.length; i++) {
			let payDate = payDates[i];
			payDate.records.forEach(it => {
				it.workPeriodStart = new Date(it.workPeriodStart)
				if (it.workPeriodEnd) {
					it.workPeriodEnd = new Date(it.workPeriodEnd)
				}
			})
			const defaultStart = this.timeLine[i].toDate();
			const time = new Date()
			defaultStart.setHours(time.getHours(), time.getMinutes(), 0, 0)
			payDate.records.push(new TimeSheetRecord(defaultStart, "REGULAR", defaultStart, employment))
			res.push(payDate)
			payDate.timePoint = this.timeLine[i]
		}

		return res;
	}

	public created(): void {
		Application.startLoading()
		this.initCurrentTimeLine(moment());
		this.loadEmployees()
			.then(() => this.loadTimeSheetPayDates())
			.then(() => this.dataComplete = true)
			.then(() => Application.stopLoading()
			);
	}

	private initCurrentTimeLine(m: Moment = moment()): void {
		const currentWeekIndexDay = moment(m).weekday()
		this.timeLine = [];
		for (let i = currentWeekIndexDay; i > 0; i--) {
			this.timeLine.push(moment(m).add({day: i * -1}))
		}
		this.timeLine.push(moment(m))
		for (let i = 1; i < 7 - currentWeekIndexDay; i++) {
			this.timeLine.push(moment(m).add({day: i}))
		}
	}

	public getDayOfWeekByIndexDay(indexDay: number): string {
		return moment().localeData().weekdaysShort()[indexDay]
	}

	public getMonthByIndexMonth(indexMonth: number): string {
		return moment().localeData().months()[indexMonth]
	}


	private expandRecord(id: string) {
		if (!document.getElementById(id)?.style.top
			|| document.getElementById(id)!.style.top == '10px') {
			document.getElementById(id)!.style.top = '30px'
		} else {
			document.getElementById(id)!.style.top = '10px'
		}
	}

	private create(record: TimeSheetRecord) {
		this.prepareRecordTime(record)
		PayrollService.createTimesheetRecord(new CreateTimeSheetRecordPayload(record)).then(
			() => {
				Notifications.success("Changes was saved")
				Application.startLoading()
				this.loadEmployees()
					.then(() => this.loadTimeSheetPayDates())
					.then(() => this.dataComplete = true)
					.then(() => Application.stopLoading())
			},
			err => processError(err, this)
		)
	}

	private update(record: TimeSheetRecord) {
		this.prepareRecordTime(record)
		PayrollService.updateTimesheetRecord(new UpdateTimeSheetRecordPayload(record)).then(
			() => {
				Notifications.success("Changes was saved")
				this.reload();
			},
			err => processError(err, this)
		)
	}

	private prepareRecordTime(record: TimeSheetRecord) {
		record.workPeriodEnd?.setFullYear(record.workPeriodStart.getFullYear(), record.workPeriodStart.getMonth(), record.workPeriodStart.getDate())
		record.workPeriodEnd?.setSeconds(0, 0)
		record.workPeriodStart.setSeconds(0, 0)
		if (record.workPeriodEnd && record.workPeriodStart.getDate() == record.workPeriodEnd.getDate() && record.workPeriodStart.getTime() > record.workPeriodEnd.getTime()) {
			record.workPeriodEnd = moment(record.workPeriodEnd).add({day: 1}).toDate()
		}
		record.originalStartTime?.setSeconds(0, 0)
		record.originalEndTime?.setSeconds(0, 0)
	}

	private editRecord(record: TimeSheetRecord) {
		this.$modal.show(
			TimeSheetRecordEditorModal,
			{
				record: record,
				onUpdate: this.update
			}
		)
	}

	private start(employment: IEmployment) {
		const defaultStart = moment().toDate();
		const time = new Date()
		defaultStart.setHours(time.getHours(), time.getMinutes(), 0, 0)
		this.create(new TimeSheetRecord(defaultStart, "REGULAR", defaultStart, employment))
	}

	private stop(record: TimeSheetRecord) {
		record.workPeriodStart = new Date(record.workPeriodStart)
		record.workPeriodEnd = new Date()
		this.update(record)
	}


	private isOpenRecordExistInDay(day: TimeSheetPayDate) {
		return !!day.records.find(it => it.workPeriodEnd == null && it.id != null)
	}

	get resolvePreviousWeek(): string {
		const previousFirstDay = moment(this.week).add({week: -1}).set({weekday: 0});
		const previousLastDay = moment(this.week).add({week: -1}).set({weekday: 6});
		return `${previousFirstDay.format("MM.DD.YYYY")} - ${previousLastDay.format("MM.DD.YYYY")}`
	}

	get resolveCurrentWeek(): string {
		const currentFirstDay = moment(this.week).set({weekday: 0});
		const currentLastDay = moment(this.week).set({weekday: 6});
		return `${currentFirstDay.format("MM.DD.YYYY")} - ${currentLastDay.format("MM.DD.YYYY")}`
	}

	get resolveNextWeek(): string {
		const nextFirstDay = moment(this.week).add({week: 1}).set({weekday: 0});
		const nextLastDay = moment(this.week).add({week: 1}).set({weekday: 6});
		return `${nextFirstDay.format("MM.DD.YYYY")} - ${nextLastDay.format("MM.DD.YYYY")}`
	}

	private remove(record: TimeSheetRecord) {
		this.$modal.show(
			DeleteConfirmation,
			{
				targetName: "timesheet record",
				onDelete: () => this.removeRecord(record)
			}
		)
	}

	private removeRecord(record: TimeSheetRecord) {
		Application.startLoading();
		PayrollService.removeRecord(record).then(
			() => {
				Notifications.success("The record was successfully deleted")
				Application.stopLoading()
				this.reload()
			},
			err => processError(err, this)
		)
	}

	timeZone(): string {
		return DateTime.local().zoneName
	}

	timeFormats(): SelectOption[] {
		return [
			SelectOption.builder().title("12 Hour").value("hh:mm A z").build(),
			SelectOption.builder().title("24 Hour").value("HH:mm z").build()
		]
	}

	changePage(page: number){
		this.employmentFilter.pageNumber = page;
		this.reload();
	}

	private buildRoutTransition(employment: IEmployment): { name: string, params: any} {
		if (employment.employmentType == EmploymentType.STAFF) {
			return {
				name: RouteNames.PAYROLL_COMPANY_EMPLOYEE_DETAILS,
				params: {
					companyId: employment.employer!.id,
					employeeId: employment.id
				}
			};
		} else {
			return {
				name: RouteNames.PAYROLL_COMPANY_INDIVIDUAL_FOREIGN_CONTRACTOR,
				params: {
					companyId: employment.employer!.id,
					contractorId: employment.id
				}
			}
		}
	}

	private openCreationReportModal() {
		this.$modal.show(
			TimeSheetReportCreationModal,
			{
				filter: this.timesheetFilter,
			}
		)
	}
}

