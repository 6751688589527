var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(_vm.id+ '_branch'),expression:"id+ '_branch'"}],staticClass:"portal-collapse-title portal-shadow",style:({
			borderBottomLeftRadius: _vm.isOpenM ? 'none': '10px',
			borderBottomRightRadius: _vm.isOpenM ? 'none': '10px'
	}),on:{"click":_vm.toggle}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between p-3 w-100"},[_vm._t("title",function(){return [_c('div',{staticClass:"col-md-11 col-sm-11 col-lg-11"},[_c('p',{staticClass:"m-0",staticStyle:{"font-size":"1.3rem"}},[_vm._v(_vm._s(_vm.label))])]),_c('font-awesome-icon',{staticClass:"col-md-1 col-sm-1 col-lg-1",staticStyle:{"transition":"transform 150ms ease-in-out"},attrs:{"icon":"fa-solid fa-caret-up","rotation":!_vm.isOpenM ? 270 : 180}})]})],2)]),_c('BCollapse',{staticClass:"portal-shadow",style:({
		paddingLeft: '20px',
		borderBottomLeftRadius: _vm.isOpenM ? '10px' : 'none',
		borderBottomRightRadius: _vm.isOpenM ? '10px' : 'none',
	}),attrs:{"id":_vm.id+ '_branch'},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{staticClass:"p-2 ",style:({
				borderLeft: '3px solid #436356',
		})},[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }