import Vue from "vue";
import Vuex from "vuex";

import Auth from "./modules/auth.module";
import Taxation from "./modules/taxation.module";
import App from "./modules/app.module"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Taxation,
    App
  },
});
